<template>
  <div class="mailing-templates-page">
    <BackTitle class="mb-40" @click="goBack">Mailing templates</BackTitle>
    <div class="mailing-templates-page__header">
      <Tabs
        v-model="statusFilter"
        :tabs="tabs"
        class="mailing-templates-page__tabs"
      />
      <Guard
        permission="mailingTemplates.create"
        v-slot="{ isAvailable }"
        class="mailing-templates-page__createButton"
      >
        <IconButton
          v-if="isAvailable"
          icon="plus"
          is-raised
          @click="goToCreation"
          class="mb-24"
        >
          New template
        </IconButton>
      </Guard>
    </div>
    <div class="mailing-templates-page__filters">
      <Input
        label="Search"
        v-model="search"
        class="mailing-templates-page__search"
      />
      <Select
        label="Event type"
        :options="eventOptions"
        v-model="selectedEventType"
        class="mailing-templates-page__eventSelect"
      />
    </div>
    <div class="grid-2 mailing-templates-page__items">
      <Loader
        v-if="isLoading"
        size="m"
        color="primary"
        class="mx-auto grid-2__full-item"
      />
      <div v-else-if="!mailingTemplates.length" class="empty grid-2__full-item">
        You haven't created mailing templates yet
      </div>
      <template v-else>
        <MailingTemplateCard
          v-for="item in mailingTemplates"
          :key="item.id"
          :subject="item.subject"
          :description="item.description"
          :event="item.event"
          :is-paused="item.isPaused"
          :menu-options="getMenuOptions(item)"
          :isPreviewLoading="
            isPreviewLoading && item.id === selectedPreviewTemplate?.id
          "
          @menu-select="handleMenuSelect($event, item)"
          @pause="handlePause(item)"
          @openPreview="handleShowPreview(item)"
        />
      </template>
    </div>
    <MailPreviewModal
      v-if="selectedPreviewTemplate"
      :modal-name="previewModalName"
      :previews="previews"
      :template-id="selectedPreviewTemplate.id"
      :type="selectedPreviewTemplate.event"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import MailingTemplateCard from "@/components/mailing-templates/MailingTemplateCard";
import dialog from "@/plugins/dialog";
import Guard from "@/components/common/Guard";
import { templateEventTypes } from "@/helpers/const";
import MailingTemplatesService from "@/api/services/MailingTemplatesService";
import MailPreviewModal from "@/components/mailing-templates/MailPreviewModal.vue";
import debounce from "debounce";

const STATUS_OPTIONS = {
  ALL: "all",
  ACTIVE: "active",
  DISABLED: "disabled",
};

export const eventOptions = [
  { value: null, name: "Any type" },
  ...templateEventTypes,
];

export default {
  name: "MailingTemplatesPage",
  components: { Guard, MailingTemplateCard, BackTitle, MailPreviewModal },
  data() {
    return {
      isLoading: false,
      deletingItemId: null,
      pausingItemId: null,
      statusFilter: STATUS_OPTIONS.ALL,
      tabs: [
        { value: STATUS_OPTIONS.ALL, name: "All" },
        { value: STATUS_OPTIONS.ACTIVE, name: "Active" },
        { value: STATUS_OPTIONS.DISABLED, name: "Disabled" },
      ],
      eventOptions,
      selectedEventType: eventOptions[0].value,
      search: "",
      previewModalName: "mailPreviewModal",
      previews: [],
      isPreviewLoading: false,
      selectedPreviewTemplate: undefined,
    };
  },
  computed: {
    ...mapState({
      mailingTemplates: (state) => state.mailingTemplates.list,
    }),
    filters() {
      return {
        seacrhValue: this.search,
        event: this.selectedEventType,
        isPaused:
          this.statusFilter === STATUS_OPTIONS.ACTIVE
            ? false
            : this.statusFilter === STATUS_OPTIONS.DISABLED
            ? true
            : undefined,
      };
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchFilteredTemplates();
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    search: debounce(async function () {
      await this.fetchFilteredTemplates();
    }, 400),
    selectedEventType: async function () {
      await this.fetchFilteredTemplates();
    },
    statusFilter: async function () {
      await this.fetchFilteredTemplates();
    },
  },
  methods: {
    ...mapActions({
      fetchMailingTemplates: "mailingTemplates/fetchMailingTemplates",
      deleteMailingTemplate: "mailingTemplates/deleteMailingTemplate",
      updateMailingTemplate: "mailingTemplates/updateMailingTemplate",
    }),
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    goToCreation() {
      this.$router.push({
        name: "CreateMailingTemplate",
      });
    },
    getMenuOptions(template) {
      return [
        {
          value: "edit",
          name: "Edit",
        },
        {
          value: "pause",
          name: template.isPaused ? "Activate" : "Pause",
        },
        {
          value: "delete",
          name: "Delete",
        },
      ];
    },
    goToEdit(template) {
      this.$router.push({
        name: "EditMailingTemplate",
        params: {
          id: template.id,
        },
      });
    },
    async handleDelete(template) {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: "All mailing template data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });

      if (confirmed) {
        try {
          this.isLoading = true;
          await this.deleteMailingTemplate(template.id);
          await this.fetchFilteredTemplates();
        } finally {
          this.isLoading = false;
        }
      }
    },
    async handlePause(template) {
      try {
        this.isLoading = true;
        await this.updateMailingTemplate({
          id: template.id,
          data: { isPaused: !template.isPaused },
        });
        await this.fetchFilteredTemplates();
      } finally {
        this.isLoading = false;
      }
    },
    async handleMenuSelect(option, template) {
      if (option.value === "edit") {
        this.goToEdit(template);
      } else if (option.value === "delete") {
        await this.handleDelete(template);
      } else if (option.value === "pause") {
        await this.handlePause(template);
      }
    },
    async handleShowPreview(template) {
      try {
        this.isPreviewLoading = true;
        this.selectedPreviewTemplate = template;
        this.previews = await MailingTemplatesService.getPreview({
          type: template.event,
          templateId: template.id,
        });
        this.$modal.show(this.previewModalName);
      } catch (err) {
        console.log("err > ", err);
      } finally {
        this.isPreviewLoading = false;
      }
    },
    async fetchFilteredTemplates() {
      try {
        this.isLoading = true;
        await this.fetchMailingTemplates({ ...this.filters });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mailing-templates-page {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 20px;
    position: relative;
  }

  &__filters {
    display: flex;
    padding: 32px 0;
    margin-bottom: 20px;
    gap: 16px;
  }

  &__createButton {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__tabs {
    gap: 32px;
    width: 100%;
    height: 48px;
  }

  &__search {
    flex: 1;
  }

  &__eventSelect {
    min-width: 240px;
  }

  &__items {
    gap: 12px;
  }
}
</style>
