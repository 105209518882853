<template>
  <CustomModal
    :name="modalName"
    classes="mail-preview-modal"
    @before-close="reset"
  >
    <div class="mail-preview-modal__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        @click="$modal.hide(modalName)"
      />
    </div>
    <div class="mail-preview-modal__header">
      <h5 class="mail-preview-modal__title">Mail preview</h5>
      <p class="mail-preview-modal__subtitle">
        This way your message will be seen by the users you've selected
      </p>
      <div class="mail-preview-modal__subject-row">
        <p
          class="mail-preview-modal__subject"
          :class="{ empty: !preview?.subject }"
        >
          {{
            preview?.subject
              ? preview.subject
              : "No content. Check your subject."
          }}
        </p>
        <div v-if="previews.length > 1" class="mail-preview-modal__arrows">
          <Icon
            name="arrow-left"
            :is-clickable="selectedPreviewIndex > 0"
            :color="
              selectedPreviewIndex <= 0 ? 'secondary-400' : 'secondary-500'
            "
            @click="handleSelectPreview(-1)"
          />
          <span>{{ selectedPreviewIndex + 1 }} of {{ previews.length }}</span>
          <Icon
            name="arrow-right"
            :is-clickable="selectedPreviewIndex < previews.length - 1"
            :color="
              selectedPreviewIndex >= previews.length - 1
                ? 'secondary-400'
                : 'secondary-500'
            "
            @click="handleSelectPreview(1)"
          />
        </div>
      </div>
    </div>
    <iframe
      v-if="preview?.html"
      :srcdoc="sanitize(preview.html)"
      class="mail-preview-modal__content"
    />
    <p v-else class="empty">No content. Check your template.</p>
    <Form v-if="preview" class="mail-preview-modal__send" v-slot="{ invalid }">
      <FormItem
        rules="required|email"
        v-slot="{ isError }"
        class="mail-preview-modal__send__input"
      >
        <Input
          v-model="email"
          label="Send test email to"
          placeholder="Enter email..."
          :is-error="isError"
        />
      </FormItem>
      <div class="mail-preview-modal__actions">
        <Button is-outlined @click="$modal.hide(modalName)"> Back </Button>
        <Button
          :is-disabled="invalid"
          :is-loading="isSubmitting"
          @click="handleSendTestMail"
        >
          Send test mail{{ previews.length > 1 ? "s" : "" }}
        </Button>
      </div>
    </Form>
  </CustomModal>
</template>

<script>
import { sanitize } from "@/helpers/utils";
import MailingTemplatesService from "@/api/services/MailingTemplatesService";
import alert from "@/plugins/alert";

export default {
  name: "MailPreviewModal",
  props: {
    modalName: {
      type: String,
      default: "mailPreviewModal",
    },
    previews: {
      type: Array,
      default: () => [],
    },
    bookingId: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    subject: {
      type: String,
      default: "",
    },
    templateId: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      email: "",
      isSubmitting: false,
      selectedPreviewIndex: 0,
    };
  },
  computed: {
    preview() {
      return this.previews[this.selectedPreviewIndex];
    },
  },
  methods: {
    sanitize,
    reset() {
      this.email = "";
      this.selectedPreviewIndex = 0;
      this.isSubmitting = false;
    },
    async handleSendTestMail() {
      try {
        this.isSubmitting = true;
        await MailingTemplatesService.sendPreview({
          email: this.email,
          bookingId: this.bookingId,
          message: this.message,
          type: this.type,
          subject: this.subject,
          templateId: this.templateId,
          text: this.text,
        });
        alert.open({
          message: `Test mail${
            this.previews.length > 1 ? "s were" : " was"
          } sent to ${this.email}`,
          variant: "success",
        });
      } catch (err) {
        console.log("err > ", err);
        alert.open({
          message: `Failed to send test email${
            this.previews.length > 1 ? "s" : ""
          }`,
          variant: "danger",
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    handleSelectPreview(inc) {
      const newIndex = this.selectedPreviewIndex + inc;
      this.selectedPreviewIndex = Math.max(
        0,
        Math.min(newIndex, this.previews.length - 1)
      );
    },
  },
};
</script>

<style lang="scss">
.mail-preview-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: unset !important;

  @media (min-width: $media-laptop) {
    &.vm--modal {
      max-width: 70% !important;
    }
  }

  &__close {
    position: absolute;
    top: 48px;
    right: 24px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $secondary-200;
    border-radius: 9999px;

    @media (min-width: $media-laptop) {
      right: 40px;
    }
  }

  &__header {
    padding: 48px 24px 24px;

    @media (min-width: $media-laptop) {
      padding: 48px 40px 24px;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 38px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 22px;
    line-height: 22px;
    color: $secondary-400;
    margin-bottom: 16px;
  }

  &__subject-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__subject {
    font-size: 18px;
    font-weight: 500;
  }

  &__arrows {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__content {
    width: 100%;
    height: 50vh;
    overflow: auto;
    border: none;
  }

  &__send {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 24px 24px 48px;

    @media (min-width: $media-laptop) {
      padding: 24px 40px 48px;
    }

    &__input {
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
