import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

export default class MailingTemplatesService extends ServiceBase {
  static getPreview({ bookingId, type, subject, message, templateId, text }) {
    return this.callApi({
      method: "POST",
      baseURL,
      url: "/private/mailing-templates/preview",
      data: {
        bookingId,
        type,
        subject,
        message,
        templateId,
        text,
      },
    });
  }

  static sendPreview({
    bookingId,
    email,
    type,
    subject,
    message,
    templateId,
    text,
  }) {
    return this.callApi({
      method: "POST",
      baseURL,
      url: "/private/mailing-templates/preview/send",
      data: {
        bookingId,
        type,
        subject,
        message,
        templateId,
        email,
        text,
      },
    });
  }

  static restore(id) {
    return this.callApi({
      method: "POST",
      baseURL,
      url: `/private/mailing-templates/${id}/restore`,
    });
  }
}
