<template>
  <div class="mailing-template-card">
    <div class="mailing-template-card__left flex-column">
      {{ subject }}
      <div class="mailing-template-card__text">
        <p v-html="sanitize(description)" />
      </div>
    </div>
    <div class="mailing-template-card__right flex-column">
      <div class="mailing-template-card__right-top">
        <Button
          variant="secondary"
          is-outlined
          class="mailing-template-card__previewButton"
          @click="$emit('openPreview')"
          :is-loading="isPreviewLoading"
        >
          <Icon name="eye" :size="25" color="black" />
          Preview
        </Button>
        <ContextMenu
          v-if="menuOptions.length"
          :options="menuOptions"
          @select="$emit('menu-select', $event)"
        >
          <Icon name="dotted-horizontal" is-clickable color="black" />
        </ContextMenu>
      </div>
      <Switcher
        v-model="isTemplateActive"
        :label="isPaused ? 'Disabled' : 'Active'"
      />
    </div>
  </div>
</template>

<script>
import { sanitize } from "@/helpers/utils";

export default {
  name: "MailingTemplateCard",
  props: {
    subject: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    event: {
      type: String,
    },
    isPaused: {
      type: Boolean,
      default: false,
    },
    menuOptions: {
      type: Array,
      default: () => [],
    },
    isPreviewLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    isTemplateActive: {
      get() {
        return !this.isPaused;
      },
      set() {
        this.$emit("pause");
      },
    },
  },
  methods: {
    sanitize,
  },
};
</script>

<style lang="scss" scoped>
.mailing-template-card {
  display: flex;
  gap: 32px;
  height: 100%;
  padding: 12px 18px;
  box-shadow: $box-shadow-small;
  border-radius: 20px;
  background-color: $white;

  &__left {
    gap: 14px;
  }

  &__right {
    justify-content: space-between;
    align-items: end;
    gap: 12px;
    flex: 1;

    &-top {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    color: $secondary-400 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__status {
    &.m-paused {
      color: $danger;
    }

    &.m-running {
      color: $success;
    }
  }

  &__previewButton {
    padding: 4px 12px;
    padding-left: 6px;
    border: 1px solid $secondary-300 !important;
    border-radius: 40px;
    color: $black !important;
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
    min-width: 94px;
    gap: 4px;
  }
}
</style>
